/*eslint-disable*/
import React from "react";
import ActeaFooter from "./ActeaFooter";
import GridItem from "./components/Grid/GridItem";
import GridContainer from "./components/Grid/GridContainer";
import { ErrorBoundary } from "./ErrorBoundary";
import ActeaHeader from "./ActeaHeader";
import Card from "./components/Card/Card";

import { makeStyles } from "@material-ui/core/styles";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
import classNames from "classnames";

const pageStyles = makeStyles(landingPageStyle);

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";
const useStyles = makeStyles(styles);


export default function RefBkgrnd() {
  const pageClasses = pageStyles();
  const classes = useStyles();

  return (
    <main>
      <ActeaHeader />
      <div className={classNames(pageClasses.main, pageClasses.mainRaised)}>
        <div className={pageClasses.container}>
          <GridContainer style={{ marginTop: 80, marginBottom: 40 }}>
            <GridItem xs={12} sm={12} md={10}>
              <Card plain>
                <h2 className={classes.title}>Actea is addressing a global scale problem:</h2>
                <h4>
                  The ocean contributes $1.5 trillion to the global economy and supplies primary protein to 3 billion people (1, 2). 600 Million people’s livelihoods are dependent on the sector (3) and a substantial proportion of seafood related jobs are filled by women (4). The economic vitality, and even the existence, of coastal communities can be tied to aquaculture and fishing (5, 6).  Conflicts driven by growing demand and climate-driven changes in fish distribution are on the rise (7).
                </h4>
                <h4>
                  Marine heatwaves already cause at least $3.1 Billion in loss of ecosystem services annually, these losses are growing and are underreported in poor countries (8). One example is the recent Alaskan Cod fishery closure, with a $100 Million impact (9). However, data driven changes in management, and planning for alternative harvests, can mitigate such losses (10, 11, 12, 13).
                </h4>
                <h4>
                  The aquaculture industry is poised to grow to $380 Billion / year by 2027 (14). An unprecedented Marine Heat Wave killed $800 Million of fish in Chile, the risks of such losses are growing (15). Furthermore, inputs to aquaculture come from wild fisheries at climate risk, such as Peruvian Anchovy (16). As such, the health and growth of the aquaculture industry are tied to the ability to model climate, in order to adapt to it (17).
                </h4>
                <h4>
                  Underwater forests are already an important carbon store, capturing up to 200 tons of carbon a year (18). Some estimates put farmed seaweed’s capacity to sequester carbon at gigaton/year scale (19). You have to know where to put these farms for them to work (20).
                </h4>
                <h4>
                  Actea’s data enables necessary fishery and aquaculture planning in a changing climate.
                </h4>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card plain>
                <h3 className={classes.cardTitle}>References</h3>

                <h4><a target='_blank' href='https://www.worldbank.org/en/topic/oceans-fisheries-and-coastal-economies' > (1) Healthy oceans provide jobs and food, sustain economic growth, regulate the climate, and support the well-being of coastal communities.</a></h4><p> by , World Bank, April 2022 </p> <p> "Billions of people worldwide —especially the world’s poorest— rely on healthy oceans as a source of jobs and food, underscoring the urgent need to sustainably use, manage and protect this natural resource. According to the OECD, oceans contribute $1.5 trillion annually in value-added to the overall economy and this number could reach $3 trillion by 2030."</p>
                <h4><a target='_blank' href='https://www.worldwildlife.org/industries/sustainable-seafood' > (2) Approximately 3 billion people in the world rely on wild-caught and farmed seafood as a primary source of protein.</a></h4><p> by , WWF, 2022 </p> <p> "As the largest traded food commodity in the world, seafood provides sustenance to billions of people worldwide. Approximately 3 billion people in the world rely on wild-caught and farmed seafood as a primary source of protein."</p>
                <h4><a target='_blank' href='https://www.fisherproject.org/the-global-fishing-industry' > (3) The Global Fishing Industry - How many people are employed in fishing?</a></h4><p> by , Fisher Project,  </p> <p> "The FAO estimates that fisheries and aquaculture support the livelihoods of 10–12 percent of the world’s population, with some 60 million people directly engaged in capture fisheries and aquaculture (some 39 million people engaged in capture fisheries), and some 200 million along the value chain from harvesting to distribution, making the livelihoods of some 660 to 820 million people dependent on the sector. Employment in the fisheries and aquaculture sectors has grown faster than the world’s population and faster than employment in traditional agriculture."</p>
                <h4><a target='_blank' href='https://www.fao.org/3/cc0461en/online/sofia/2022/fisheries-aquaculture-employment.html' > (4) EMPLOYMENT IN FISHERIES AND AQUACULTURE</a></h4><p> by , FAO, 2022 </p> <p> "In 2012, the World Bank1 estimated that in certain segments of the processing sector worldwide, up to 85 percent of the employees were women. Further, the preliminary results of the recent Illuminating Hidden Harvests study2 show half of the workers in the small-scale fisheries’ post-harvest sector to be women. "</p>
                <h4><a target='_blank' href='https://www.aquaculturenorthamerica.com/how-aquaculture-changed-these-newfoundland-communities/' > (5) How aquaculture changed these Newfoundland communities</a></h4><p> by Liza Mayer, Aquaculture North America, July 2020 </p> <p> A discusion of the job losses and broader impacts of the Newfoundland Cod closure and how Aquaculture is revitalizing coastal communities. </p>
                <h4><a target='_blank' href='https://www.sciencedirect.com/science/article/pii/S0964569120304051' > (6) Fisheries decline, local livelihoods and conflicted governance: An Indonesian case</a></h4><p> by Carol Warrena and Dirk J. Steenbergen, Science Direct, March 2021 </p> <p> "This study investigates the social and environmental impacts of the rise and decline of the fishing industry in an Indonesian coastal community as a case study of the conflicted role of governance in marine resource management."</p>
                <h4><a target='_blank' href='https://hakaimagazine.com/news/international-fish-fights-on-the-rise/' > (7) International Fish Fights on the Rise</a></h4><p> by Chris Baraniuk, Hakai Magazine, July 2019 </p> <p> "A new report shows that there is increasing competition between countries for access to seafood."</p>
                <h4><a target='_blank' href='https://www.science.org/doi/10.1126/science.abj3593' > (8) Socioeconomic impacts of marine heatwaves: Global issues and opportunities</a></h4><p> by Kathryn E. Smith et al., Science, October 2021 </p> <p> The prior news article was based on this Science paper. It is behind a pay wall, however the abstract is available. It notes "in excess of US$3.1 billion per annum in indirect losses" from Marine Heat Waves.</p>
                <h4><a target='_blank' href='https://www.npr.org/2019/12/08/785634169/alaska-cod-fishery-closes-and-industry-braces-for-ripple-effect' > (9) Alaska Cod Fishery Closes And Industry Braces For Ripple Effect</a></h4><p> by KAVITHA GEORGE, NPR, Dec 2019 </p> <p> A discussion of the impacts of the 2020 closure of the Pacific Cod season. </p>
                <h4><a target='_blank' href='https://www.pnas.org/doi/10.1073/pnas.1909726116' > (10) Effective fisheries management instrumental in improving fish stock status</a></h4><p> by Ray Hilborn, Ricardo Oscar Amoroso, Christopher M. Anderson, and Yimin Ye, Proceedings of the National Acadamy of Sciences, January 2020 </p> <p> "We... demonstrate that where fisheries are intensively managed, the stocks are above target levels or rebuilding. Where fisheries management is less intense, stock status and trends are worse."</p>
                <h4><a target='_blank' href='https://toolkit.climate.gov/topics/marine/fisheries-and-coastal-communities' > (11) Marine fisheries and the fishing communities they support are at high risk from climate-driven changes in marine ecosystems.</a></h4><p> by NOAA Fisheries Climate Science Stragety, US Climate Resiliance Toolkit, Aug 2015 </p> <p> "Climate change may seem far off in the future to some people, but for many who work in fisheries, it’s definitely here and it’s already having a big effect."</p>
                <h4><a target='_blank' href='https://www.pnas.org/doi/10.1073/pnas.1701262114' > (12) Marine reserves can mitigate and promote adaptation to climate change</a></h4><p> by Callum M. Roberts et al. , Proceedings of the National Academy of Sciences, June 2017 </p> <p> "We highlight both strengths and limitations and conclude that marine reserves are a viable low-tech, cost-effective adaptation strategy that would yield multiple cobenefits from local to global scales, improving the outlook for the environment and people into the future."</p>
                <h4><a target='_blank' href='https://www.sciencedaily.com/releases/2021/01/210105160816.htm' > (13) Dungeness crab fishing industry response to climate shock</a></h4><p> by NOAA Fisheries West Coast Region, Science Daily, January 2021 </p> <p> A NOAA analysis found that boats that already fished multiple species were able to adapt to California's dungenese crab closure (caused by a marine heat wave). Boats that didn't already fish alternatives were idled. By predicting what alternative species will be available in a region in the future, and which are more subject to shocks, Actea can help commercial fishermen build resiliency. </p>
                <h4><a target='_blank' href='https://www.alliedmarketresearch.com/aquaculture-market' > (14) Aquaculture Market by Environment and Fish Type: Global Opportunity Analysis and Industry Forecast, 2021–2027</a></h4><p> by Allied Market Research, Allied Market Research, November 2020 </p> <p> "The global aquaculture market size was valued at $285,359.7 million in 2019, and is projected to reach $378,005.5 million by 2027"</p>
                <h4><a target='_blank' href='https://www.reuters.com/article/us-chile-salmon-idUSKCN0WC0A2' > (15) Chile's salmon farms losing up to $800 million from algal bloom</a></h4><p> by Anthony Esposito, Reuters, March 2016 </p> <p> A marine heat wave was a primary cause of the algal bloom. Actea models algal production. The article also includes some further details including the size of the industry, percentage of fish lost, and some detail on locations and companies experiencing losses. </p>
                <h4><a target='_blank' href='https://www.science.org/content/article/climate-change-threatens-one-world-s-biggest-fish-harvests' > (16) Climate change threatens one of world’s biggest fish harvests</a></h4><p> by ERIK STOKSTAD, Science, January 2022 </p> <p> The Peruvian Anchovy is the largest fish harvest in the world, and is primarily used as feed for farmed Salmon. They are at risk to climate change.</p>
                <h4><a target='_blank' href='https://journals.plos.org/climate/article?id=10.1371/journal.pclm.0000017' > (17) Insight into real-world complexities is required to enable effective response from the aquaculture sector to climate change</a></h4><p> by Lynne Falconer et al., PLOS Climate, March 2022 </p> <p> Actea has already built the tools that this study calls for:  "This study demonstrates how a comprehensive knowledge base can be used by the aquaculture industry, researchers, and policymakers as a foundation for more targeted and detailed climate change impact analysis, risk assessments and adaptation planning... Since aquaculture includes a diversity of production systems, species, and farming environments, assessment of climate change effects is complex as impacts will vary considerably, as will the ability to adapt and respond. As in other sectors, it is often local-scale issues that are most relevant to individuals, businesses, or communities, and these are not captured in high-level or generalised assessments."  </p>
                <h4><a target='_blank' href='https://www.nature.com/articles/ngeo2790' > (18) Substantial role of macroalgae in marine carbon sequestration</a></h4><p> by Dorte Krause-Jensen et al., Nature Geoscience, September 2016 </p> <p> "A rough estimate suggests that macroalgae could sequester about 173 TgC yr−1 (with a range of 61–268 TgC yr−1) globally."</p>
                <h4><a target='_blank' href='https://static1.squarespace.com/static/58ec123cb3db2bd94e057628/t/5fda3ec0e28fdf61aebf3159/1608138446173/Uncharted+Waters+Final+12.16.20.pdf' > (19) Uncharted Waters: Expanding the Options for Carbon Dioxide removal in Coastal and Ocean Environments</a></h4><p> by Energy Futures Initiative, energyfuturesinitiative.org, 2020 </p> <p> "Marine CDR pathways can capture and sequester CO2 at billion ton (Gt) scale in because of the sheer amount of available space in the ocean and the absence of land use complications. Additionally, there are numerous marine CDR pathways available." "Marine macroalgae (seaweed) ecosystems (e.g., kelp forests and sargassum rafts) far exceed coastal blue carbon systems in terms of potential carbon sequestration capacity."</p>
                <h4><a target='_blank' href='https://www.int-res.com/abstracts/meps/v537/p89-104/' > (20) Kelp in hot water: I. Warming seawater temperature induces weakening and loss of kelp tissue</a></h4><p> by E. J. Simonson et al., Marine Ecology Progres Series, October 2015 </p> <p> As little as a week of 21 degree water, or 3 weeks at 18 degrees, will dramatically weaken kelp. </p>
              </Card>
              <Card plain>
                <h3 className={classes.cardTitle}>Some other interesting links</h3>

                <h4><a target='_blank' href='https://www.houstonpublicmedia.org/articles/news/in-depth/2022/03/21/421582/texas-oyster-industry-struggles-to-get-by-as-nearly-all-the-states-bays-are-closed-for-harvesting/' > The Texas oyster industry is struggling as the state’s reefs close for harvesting</a></h4><p> by KATIE WATKINS, Houston Public Media, March 2022 </p> <p> "Texas has closed nearly all of the state’s public oyster reefs for harvesting, dealing a devastating blow to those who work in the industry. But state biologists say it’s necessary to protect the bivalves for the future."</p>
                <h4><a target='_blank' href='https://www.fishfarmingexpert.com/article/marine-heatwaves-more-frequent-and-damaging/' > Marine heatwaves becoming more frequent and longer</a></h4><p> by The Editors, Fish Farming Expert, December 2021 </p> <p> This article has a list of marine heatwaves and associated impacts. Note the $800 M Chilean salmon die off and the $100 M closure of the Pacific Cod fishery in the Gulf of Alaska.</p>
                <h4><a target='_blank' href='https://www.nature.com/articles/s41586-022-04674-5#:~:text=Although%20climate%20change%20will%20challenge,and%20expand%20sustainable%20mariculture%20operations.' > Expanding ocean food production under climate change</a></h4><p> by Christopher M. Free et al., Nature, April, 2022 </p> <p> This paper highlights the need for the climate adaptions that Actea can help plan: "We find that climate-adaptive fisheries reforms will be necessary but insufficient to maintain global seafood production per capita, even with aggressive reductions in greenhouse-gas emissions. However, the potential for sustainable mariculture to increase seafood per capita is vast and could increase seafood production per capita under all but the most severe emissions scenario."</p>
                <h4><a target='_blank' href='https://www.researchgate.net/publication/325871167_Impacts_of_Climate_Change_on_Fisheries_and_Aquaculture_Synthesis_of_Current_Knowledge_Adaptation_and_Mitigation_Options' > Impacts of Climate Change on Fisheries and Aquaculture. Synthesis of Current Knowledge, Adaptation, and Mitigation Options</a></h4><p> by Manuel Barange et al., Food and Agriculture Organization of the United Nations, May 2018 </p> <p> A 600+ page in depth analysis. A quote from the introduction: "For marine aquaculture, Norway and Chile were identified as being the most vulnerable, due to their high production, although China, Viet Nam, the Philippines and Madagascar were also considered to be highly vulnerable. Climate driven changes in temperature, precipitation, ocean acidification, incidence and extent of hypoxia and sea level rise, amongst others, are expected to have long-term impacts in the aquaculture sector at multiple scales. Options for adaptation and resilience building are offered, noting that interactions between aquaculture, fisheries and agriculture can either exacerbate the impacts or help create solutions for adaptation."</p>
                <h4><a target='_blank' href='https://www.futuremares.eu/nature-based-solutions' > Nature-Based Solutions (NBS) for adapting marine ecosystems to a changing climate</a></h4><p> by Future Marers, Future Mares Website, 2021 </p> <p> Actea's data is being used in planning of Nature Based Solutions to climate change by the EU Future Mares project. </p>

              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <ActeaFooter />

    </main>
  );
}