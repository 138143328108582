/*eslint-disable*/ import React from "react";
import ActeaFooter from "./ActeaFooter";
import { ErrorBoundary } from "./ErrorBoundary";
import GridItem from "./components/Grid/GridItem";
import GridContainer from "./components/Grid/GridContainer";

export default function PP() {


  return (
    <main>
      <ErrorBoundary>
        <GridContainer style={{ padding: 45 }}>
        <GridItem xs={12} sm={12} md={8}>
          <p>
            <strong>ACTEA PRIVACY POLICY</strong>
          </p>
          <p>Last Updated January 28, 2022</p>
          <p>
            Welcome to the website (the “<strong>Site</strong>”) of Actea, Inc. (“
            <strong>Actea</strong>,” “<strong>we</strong>,” “<strong>us</strong>,” or “{" "}
            <strong>our</strong>”). <a name="_Hlk89333855" />
            <a name="_Hlk80368564">
              The Site is directed towards our customers and potential customers. It
              should not be used for personal, family, or household purposes. We treat
              all personal information covered by this Privacy Policy as pertaining to
              individuals acting as business representatives, rather than in their
              personal capacity
            </a>
            .
          </p>
          <p>
            This Privacy Policy explains what personal information we collect, how we
            use and share that information, and your choices concerning our information
            practices.
          </p>
          <p>
            Before using the Site or submitting any personal information to Actea,
            please review this Privacy Policy carefully and contact us if you have any questions. By using the
            Site, you agree to the practices described in this Privacy Policy. If you do
            not agree to this Privacy Policy, please do not use the Site.
          </p>
          <p>
            <strong>1. </strong>
            <strong>PERSONAL INFORMATION COLLECTION</strong>
          </p>
          <p>
            <strong>Personal Information You Provide:</strong>
          </p>
          <ul>
            <li>
              <strong>Identification Information:</strong>
              we may collect your name, email address, and phone number through the Site
              or through our social media platforms.
            </li>
            <li>
              <strong>Communication Information: </strong>
              we may collect any information included in your communications with us.
            </li>
          </ul>
          <p>
            <a>
              <strong>Internet Activity Information:</strong>
            </a>
            When you visit the Site, the following information we may automatically log
            the following information:
          </p>
          <p>
            ● <strong>Device Information</strong>: the manufacturer and model, operating
            system, IP address, and unique identifiers of the device, as well as the
            browser you use to access the Site. The information we collect may vary
            based on your device type and settings.
          </p>
          <p>
            ● <strong>Usage Information</strong>: information about how you use our
            Site, such as the types of content that you view or engage with, the
            features you use, the actions you take, and the time, frequency, and
            duration of your activities. We may use Google Analytics, a web analytics
            service provided by Google LLC (“Google”) to help collect and analyze Usage
            Information. For more information on how Google uses this information, click{" "}
            <a href="http://www.google.com/policies/privacy/partners/">here</a>.
          </p>
          <p>
            The following technologies may be used to collect Internet Activity
            Information:
          </p>
          <ul>
            <li>
              <strong>Cookies</strong>, which are text files stored on your device to
              uniquely identify your browser or to store information or settings in the
              browser to help you navigate between pages efficiently, remember your
              preferences, enable functionality, help us understand user activity and
              patterns, and facilitate online advertising.
            </li>
            <li>
              <strong>Local storage technologies</strong>, like HTML5, that provide
              cookie-equivalent functionality but can store larger amounts of data,
              including on your device outside of your browser in connection with
              specific applications.
            </li>
            <li>
              <strong>Web beacons</strong>, also known as pixel tags or clear GIFs,
              which are used to demonstrate that a webpage or email was accessed or
              opened, or that certain content was viewed or clicked.{" "}
            </li>
          </ul>
          <p>
            <strong>2. </strong>
            <strong>PERSONAL INFORMATION USE</strong>
          </p>
          <p>We use your personal information for:</p>
          <p>
            <strong>Site Delivery</strong>, including to provide, operate, maintain, and
            secure the Site.
          </p>
          <p>
            <strong>Communicating with You</strong>, including to:
          </p>
          <ul>
            <li>
              send you updates about administrative matters such as changes to our terms
              or policies; and
            </li>
            <li>
              provide user support, and respond to your requests, questions and
              feedback.
            </li>
          </ul>
          <p>
            <strong>Site Improvement</strong>
            , including to:
            <strong />
          </p>
          <ul>
            <li>improve the Site and create new features;</li>
            <li>personalize your experience; and</li>
            <li>
              create and derive insights from de-identified and aggregated information.
            </li>
          </ul>
          <p>
            <a name="compliance_and_protection">
              <strong>Marketing and Advertising:</strong>
            </a>
            we and our advertising partners may use your personal information for
            marketing and advertising purposes, including:
          </p>
          <ul>
            <li>
              <strong>Direct Marketing:</strong>
              to send you direct marketing communications as permitted by law,
              including, but not limited to, notifying you of special promotions, offers
              and events via email and other means.
            </li>
          </ul>
          <p>
            <a name="_Hlk89384563" />
            <a name="interest_based_advertising" />
            <a>
              · <strong>Interest-Based Advertising</strong>
            </a>
            <strong>:</strong>
            we may engage third party advertising and social media companies to display
            ads on the Site and other online services. These companies may use cookies
            and similar technologies to collect information about your interaction over
            time across the Site, our communications, and other online services, and use
            that information to serve online ads that they think will interest you. This
            is called interest-based advertising. We may also share information about
            our users with these companies to facilitate interest-based advertising to
            our or similar users (known as a “lookalike audience”) on other online
            platforms. You can learn more about your choices for limiting interest-based
            advertising in the <a href="#onlinetrackingoptout">
            Limit Online Tracking
          </a>{" "}
            section below.
          </p>
          <p>
            <strong>Compliance and Protection</strong>
            <strong>, </strong>
            including to:
          </p>
          <ul>
            <li>
              Comply with applicable laws, lawful requests, and legal process, such as
              to respond to subpoenas or requests from government authorities;
            </li>
            <li>
              Protect our, your or others’ rights, privacy, safety or property
              (including by making and defending legal claims);
            </li>
            <li>
              Audit our compliance with legal and contractual requirements and internal
              policies; and
            </li>
            <li>
              Prevent, identify, investigate and deter fraudulent, harmful,
              unauthorized, unethical or illegal activity, including cyberattacks and
              identity theft.
            </li>
          </ul>
          <p>
            <strong>3. </strong>
            <strong>PERSONAL INFORMATION SHARING</strong>
          </p>
          <p>In certain circumstances we may share your personal information with:</p>
          <p>
            <a name="_Hlk58420531">
              · <strong>Service Providers:</strong>
            </a>
            including hosting services, cloud services, and other information technology
            services, email communication software and email newsletter services,
            advertising and marketing services, customer relationship management and
            customer support services, and analytics services. Pursuant to our
            instructions, these parties will access, process, or store personal
            information in the course of performing their duties to us.
          </p>
          <ul>
            <li>
              <strong>Professional Advisors:</strong>
              such as lawyers and accountants where doing so is necessary to facilitate
              the services they render to us.
            </li>
            <li>
              <strong>Business Transaction Recipients: </strong>
              such as counterparties and others assisting with a merger, acquisition,
              financing, reorganization, bankruptcy, receivership, dissolution, asset
              sale, or similar transaction, and with a successor or affiliate as part of
              or following that transaction.
            </li>
            <li>
              <strong>Government Authorities:</strong>
              we do not volunteer your personal information to government authorities or
              regulators, but we may disclose your personal information where required
              to do so for the Compliance and Protection purposes described above.
            </li>
          </ul>
          <p>
            <strong>4. </strong>
            <strong>CHILDREN</strong>
          </p>
          <p>
            Our Site is not directed to children who are under the age of 16. Actea does
            not knowingly collect personal information from children under the age of
            16. If you believe that a child under the age of 16 provided personal
            information to Actea please <a href="#Contact_Us">contact us</a> and we will
            delete that information.
          </p>
          <p>
            <strong>5. </strong>
            <strong>LINKS TO OTHER WEBSITES</strong>
            <u />
          </p>
          <p>
            The Site may contain links to other websites not operated or controlled by
            Actea, including social media services (“<strong>Third Party Sites</strong>
            ”). The information that you share with Third Party Sites will be governed
            by the specific privacy policies and terms of service of the Third Party
            Sites and not by this Privacy Policy. By providing these links we do not
            imply that we endorse or have reviewed these sites. Please contact the Third
            Party Sites directly for information on their privacy practices and
            policies.
          </p>
          <p>
            <a name="Security">
              <strong>6. </strong>
              <strong>SECURITY</strong>
            </a>
          </p>
          <p>
            <a name="_Hlk15652078">
              We use reasonable technical, organizational and physical safeguards
              designed to protect the personal information we collect. However, no
              security measures are failsafe and we cannot guarantee the security of
              your personal information. You use the Site at your own risk.
            </a>
          </p>
          <p>
            <strong>7. </strong>
            <strong>YOUR</strong>
            <strong> CHOICES</strong>
            <strong />
          </p>
          <p>
            <strong>Update or Correct Personal Information:</strong>
            You can contact us and request any updates or corrections needed to keep
            your personal information accurate, current, and complete.
          </p>
          <p>
            <a name="optoutmarketing">
              <strong>Opt Out of Marketing Communications</strong>
            </a>
            <strong>: </strong>
            You may opt out of marketing-related communications by following the opt out
            or unsubscribe instructions contained in any marketing communication we send
            you. Please note, however, that you may continue to receive communications
            as described in the Communicating with You section after opting out of
            marketing communications.
          </p>
          <p>
            <a name="_Hlk89384642" />
            <a name="onlinetrackingoptout" />
            <a>
              <strong>Limit Online Tracking</strong>
            </a>
            <strong>:</strong>
            Here are some of the ways you can limit online tracking:
          </p>
          <ul type="disc">
            <li>
              <strong>Block Cookies:</strong>
              Most browsers let you remove or reject cookies, including cookies used for
              interest-based advertising. To do this, follow the instructions in your
              browser settings. Many browsers accept cookies by default until you change
              your settings. Use the following links to learn more about how to control
              cookies and online tracking through your browser:
            </li>
            <ul type="circle">
              <li>
                <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop">
                  Firefox
                </a>
                ; <a href="https://support.google.com/chrome/answer/95647">Chrome</a>;
                <a
                  href="https://support.microsoft.com/en-us/microsoft-edge/learn-about-tracking-prevention-in-microsoft-edge-5ac125e8-9b90-8d59-fa2c-7f2e9a44d869">
                  Microsoft Edge
                </a>
                ;
                <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                  Safari
                </a>
              </li>
            </ul>
            <li>
              <strong>Use Privacy Plug-Ins or Browsers:</strong>
              You can block our Site from setting cookies used for interest-based ads by
              using a browser with privacy features, like{" "}
              <a href="https://brave.com/">Brave</a>, or installing browser plugins like{" "}
              <a href="https://www.eff.org/privacybadger">Privacy Badger</a>,
              <a href="https://duckduckgo.com/">DuckDuckGo</a>,
              <a href="https://www.ghostery.com/">Ghostery</a> or{" "}
              <a href="https://ublock.org/">uBlock Origin</a>, and configuring them to
              block third party cookies/trackers. You can also install a browser add-on
              to opt out of{" "}
              <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics</a>.
            </li>
            <li>
              <strong>Advertising Industry Opt-Outs:</strong>
              You can also use these opt-out options to limit use of your information
              for interest-based advertising by participating companies:
            </li>
            <ul type="circle">
              <li>
                <a href="http://optout.aboutads.info/">Digital Advertising Alliance</a>
              </li>
              <li>
                <a href="http://optout.networkadvertising.org/?c=1">
                  Network Advertising Initiative
                </a>
              </li>
            </ul>
            <li>
              <strong>Platform Opt-Outs.</strong>
              The following advertising platforms offer opt-out features that let you
              opt out of use of your information for interest-based advertising:
            </li>
            <ul type="circle">
              <li>
                Google <a href="https://adssettings.google.com/">opt-out</a>
              </li>
              <li>
                LinkedIn
                <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">
                  opt-out
                </a>
              </li>
            </ul>
          </ul>
          <p>
            Note that the above opt-out mechanisms are specific to the device or browser
            on which they are exercised so you will need to opt out on every browser and
            device that you use.
          </p>
          <p>
            <strong>Do Not Track. </strong>
            We currently do not respond to "Do Not Track" or similar signals. Learn more
            about "Do Not Track" <a href="http://www.allaboutdnt.com">here</a>.
          </p>
          <p>
            <strong>8. </strong>
            <strong>CHANGES</strong>
            <strong> TO THE PRIVACY POLICY</strong>
            <strong />
          </p>
          <p>
            The Site and our business may change from time to time. As a result we may
            change this Privacy Policy at any time. When we do we will post an updated
            version on this page, unless another type of notice is required by law. By
            continuing to use our Site or providing us with personal information after
            we have posted an updated Privacy Policy, you consent to the revised Privacy
            Policy and the practices described in it.
          </p>
          <p>
            <a name="Contact_Us" />
            <a name="_cp_text_2_284" />
            <a name="_cp_text_2_285" />
            <a name="_cp_text_2_287" />
            <strong>9. </strong>
            <strong>CONTACT</strong>
            <strong> US</strong>
            <strong />
          </p>
          <p>
            If you have any questions about our Privacy Policy or information practices,
            please feel free to contact us at <a>info@actea.earth</a>{" "}
          </p>
          <div>
            <hr align="left" size={1} width="33%" />
            <div>
            </div>
          </div>
        </GridItem>
        </GridContainer>
      </ErrorBoundary>



      <ActeaFooter/>


    </main>
  );
}
