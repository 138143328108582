

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js";
const useStyles = makeStyles(styles);
const cors = require('cors')({origin: true});


export default function WorkSectionAlt() {
  const classes = useStyles();




  return (

    <div className={classes.section}>
      <GridContainer justify="center" spacing={1} style={{margin:'10px'}}>
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title} id={"contactus"}>Contact us</h2>
          <h4 className={classes.description} style={{color: "#3C4858"}}>
            We look forward to discussing how Actea can help your organization, email us at info @ actea.earth (without the spaces).
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}

