/*eslint-disable*/ import React from "react";
import ActeaFooter from "./ActeaFooter";
import ActeaHeader from "./ActeaHeader";

import GridItem from "./components/Grid/GridItem";
import GridContainer from "./components/Grid/GridContainer";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";


import TK from "assets/img/tk.png";
import JM from "assets/img/JHM.png";
import SM from "assets/img/SM.png";
import HEF from "assets/img/HF2022.png";
import EJ from "assets/img/EJ.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
const pageStyles = makeStyles(landingPageStyle);

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";
const useStyles = makeStyles(styles);


export default function Team() {
  const pageClasses = pageStyles();
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );


  return (
    <main>
      <ActeaHeader/>

      <div className={classNames(pageClasses.main, pageClasses.mainRaised)}>

        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Actea's Team</h2>
              <h5 className={classes.description}>
                Actea's success is built on its team's cross disciplinary expertise in Ocean Climate, Fish Biology, Financial Risk Management, and Scalable Information Systems.
              </h5>
            </GridItem>
          </GridContainer>

          <h2 className={classes.title}></h2>
          <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card plain style={{paddingLeft:5,paddingRight:5}}>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img src={TK} style={{width:208}} alt="..." className={imageClasses} />
                  </GridItem>
                  <h4 className={classes.cardTitle}> Dr. Trond Kristiansen, Cofounder, CTO<br />
                  </h4>
                  <p className={classes.description} style={{color:'rgba(0, 0, 0, 0.87)'}}>
                    Trond has more than 20 years of experience modeling ocean ecosystems, including how they will be impacted by climate change, fisheries, and activities like oil exploration. Trond has worked in Norway at IMR and NIVA. For the last several years, Trond has been creating high-resolution climate information to inform decision making by different actors.  In addition to his role at Actea Trond is a Principal Scientist at the Farallon Institute.
                  </p>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <Card plain style={{paddingLeft:5,paddingRight:5}}>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img src={JM} alt="..." className={imageClasses} />
                  </GridItem>
                  <h4 className={classes.cardTitle}> Jordan Miller, Cofounder, CEO <br />
                  </h4>
                  <p className={classes.description} style={{color:'rgba(0, 0, 0, 0.87)'}}> Jordan cofounded the computer vision and sensor fusion startup RealityCap, acquired by Intel. Jordan developed risk management systems at Highbridge Capital, at the time one of the largest hedge funds in the world. For over 20 years Jordan has pragmatically applied statistical models to solve real world problems.
                  </p>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <Card plain style={{paddingLeft:5,paddingRight:5}}>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img src={SM} alt="..." className={imageClasses} />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Samuel Makonnen, Engineering Lead
                    <br />
                  </h4>
                  <p className={classes.description} style={{color:'rgba(0, 0, 0, 0.87)'}}>
                    Sam has 20 years of experience working as a full stack engineer on web scale projects. Having worked on one of the internet's first price search engines (StreetPrices.com) as well as a full internet search engine (Blekko), Sam knows petabyte scale. Sam was tech lead of a team at IBM Watson to incorporate domain-specific data pipelines into the AI.
                  </p>

                </Card>
              </GridItem>
            </GridContainer>
            </div>

            <h2 className={classes.title}>Actea's Advisors</h2>
            <div>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={4}>
                  <Card plain style={{paddingLeft:5,paddingRight:5}}>
                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                      <img src={HEF} style={{width:208}} alt="..." className={imageClasses} />
                    </GridItem>
                    <h4 className={classes.cardTitle}> Dr. Halley E. Froehlich<br />
                    </h4>
                    <p className={classes.description} style={{color:'rgba(0, 0, 0, 0.87)'}}>
                      Dr. Froehlich is an Assistant Professor at the University of California, Santa Barbara in the Department of Ecology, Evolution, & Marine Biology and Environmental Studies Program. Professor Froehlich earned her B.Sc. in Animal Biology from the University of California, Davis (2009) and her Ph.D. in Marine Ecology & Fishery Sciences from the University of Washington (2015). She is a leading scientist in the study of the impacts and interactions of aquaculture, wild capture fisheries, and climate change. Dr. Froehlich was a contributing author to the recent Sixth Intergovernmental Panel on Climate Change Assessment Report on Impacts, Adaptation, and Vulnerability for the North American Chapter. She is a current author on the upcoming Fifth US National Climate Assessment, contributing to the Southwest chapter. Learn more about her lab and research <a href={'https://froehlichlab.eemb.ucsb.edu/'}>here</a>
                    </p>
                  </Card>
                </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Card plain style={{paddingLeft:5,paddingRight:5}}>
                      <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                        <img src={EJ} style={{width:208}} alt="..." className={imageClasses} />
                      </GridItem>
                      <h4 className={classes.cardTitle}> Dr. Eagle Jones<br />
                      </h4>
                      <p className={classes.description} style={{color:'rgba(0, 0, 0, 0.87)'}}>
                        Dr. Jones is a pioneer in visual-inertial sensor fusion / SLAM and autonomous driving, and an expert in computer vision and machine learning. He developed and raced autonomous vehicles in the 2004 and 2005 DARPA grand challenges, leading to his research focus on multi-sensor fusion for state estimation. His technical skills are balanced by his experience as a team leader, corporate strategist, and problem solver, developed during his time in strategy consulting for top Fortune 50 clients at McKinsey. He has founded or been an early employee at six startups and has led large international teams developing virtual and augmented reality technology at both Intel and Meta.
                      </p>
                    </Card>
                  </GridItem>
              </GridContainer>

              </div>
        </div>
      </div>

      <ActeaFooter/>

    </main>
  );
}