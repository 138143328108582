/*eslint-disable*/ import React, { useState } from "react";
import LandingPage from "./LandingPage/LandingPage";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";


export default function App() {
  const [cookiesAccepted, setCookiesAccepted] = React.useState(false);

  const handleAcceptCookie = () => {
    setCookiesAccepted(true);
  };


  React.useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);


  return (
    <div>
      <LandingPage cookieState={cookiesAccepted}/>
      <CookieConsent
        onAccept={handleAcceptCookie}
      >
        This website uses cookies to improve user experience and enable security features including recaptcha.
      </CookieConsent>
    </div>
  );
}