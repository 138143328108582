import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone';

const styles = theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	container: {
		width: '20px', // this does not work
	}
});

function CMIP6_physics_list(props) {
	const { bgColor, classes } = props;
	return (
		<div className={classes.root} style={{backgroundColor: bgColor}}>
			<List component="nav">
				<ListItem button>
					<ListItemIcon className="container">
						<FiberManualRecordTwoToneIcon style={{ fontSize: 10, color:"#3C4858"}} />
					</ListItemIcon>
					<ListItemText secondary="Individual asset risks" color="secondary" style={{display:'flex', justifyContent:'flex-start'}} />
				</ListItem>
				<ListItem button>
					<ListItemIcon className="container">
						<FiberManualRecordTwoToneIcon style={{ fontSize: 10, color:"#3C4858"}} />
					</ListItemIcon>
					<ListItemText secondary="Scenario Analysis" color="secondary" style={{display:'flex', justifyContent:'flex-start'}} />
				</ListItem>
				<ListItem button>
					<ListItemIcon className="container">
						<FiberManualRecordTwoToneIcon style={{ fontSize: 10, color:"#3C4858"}} />
					</ListItemIcon>
					<ListItemText secondary="Portfolio Optimization" color="secondary" style={{display:'flex', justifyContent:'flex-start'}} />
				</ListItem>
				<ListItem button>
					<ListItemIcon className="container">
						<FiberManualRecordTwoToneIcon style={{ fontSize: 10, color:"#3C4858"}} />
					</ListItemIcon>
					<ListItemText secondary="Correlation analysis" color="secondary" style={{display:'flex', justifyContent:'flex-start'}} />
				</ListItem>
				<ListItem button>
					<ListItemIcon className="container">
						<FiberManualRecordTwoToneIcon style={{ fontSize: 10, color:"#3C4858"}} />
					</ListItemIcon>
					<ListItemText secondary="Loss Distributions" color="secondary" style={{display:'flex', justifyContent:'flex-start'}} />
				</ListItem>
			</List>
		</div>
	);
}

CMIP6_physics_list.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CMIP6_physics_list);
