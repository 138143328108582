import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";

const useStyles = makeStyles(styles);

import Actea_process from "../../assets/img/Actea_process_cc.png";
import coarseScale from "../../assets/img/coarseScale.png";
import fineScale from "../../assets/img/fineScale.png";


export default function WhatActeaDoes() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgFluid
  );
  return (
    <div className={classes.container}>

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.title} >Actea's Innovation</h3>
            <h4 style={{fontFamily:"Arial,sans-serif", alignment: 'center', color:"#3C4858"}}>Actea is a public benefits corporation whose mission is to help the world adapt to climate change through the productive utilization of the oceans, specifically by providing the required insights into the future of ocean climate in order to enable long term investment. Actea's innovative application of machine learning to ocean climate modeling underlies these insights.</h4>
            <Card plain>
              <img src={Actea_process}  width={700} style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <h4 className={classes.cardTitle}> Global climate projections have limitations <br />
              </h4>
              <h5 style={{fontFamily:"Arial,sans-serif", alignment: 'center', color:"#3C4858"}}> Climate modeling is done by public institutions on a global scale: the IPCC report is based in part on a consortium of climate models known as <a target="_blank" href={'https://climate.copernicus.eu/latest-projections-future-climate-now-available'}>CMIP6</a>. These models, however, are coarse scale models, with pixels of 100km on a side. They also have statistical biases. As such, they can’t accurately project how climate change will impact a specific location.
              </h5>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <h4 className={classes.cardTitle}> Precision allows insights <br />
              </h4>
              <h5 style={{fontFamily:"Arial,sans-serif", alignment: 'center', color:"#3C4858"}}> The Ocean Climate experts at Actea combine high resolution ocean observations with the low resolution CMIP6 models using our patent pending statistical algorithms. Actea achieves over 100x the detail of CMIP6 projections and removes the biases. Actea’s projections allow for new questions to be answered. Actea further uses its expertise in marine biology to turn climate projections into actionable insights for fishery and mariculture stakeholders.
              </h5>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
