/*eslint-disable*/ import React from "react";
import { Outlet} from "react-router-dom"
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js"

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

const useStyles = makeStyles(landingPageStyle);
import WorkSectionAlt from "./Sections/WorkSectionAlt.js";
import banner1 from "assets/img/darkend_delta_t.png";
import ActeaFooter from "../ActeaFooter";
import { ErrorBoundary } from "../ErrorBoundary";
import WhatActeaDoes from "./Sections/WhatActeaDoes";
import HowActeaHelps from "./Sections/HowActeaHelps";
import ProductList from "./Sections/ProductList";

const dashboardRoutes = [];

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Actea Inc"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={banner1}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              <Card plain>
              <h1 className={classes.title} width={'100%'}  >Oceans are changing.</h1>
              <h3 style={{fontWeight : 'bold', color:'white'}}>
                How do you plan for a future that is nothing like the past?
                <br/><br/>
                Scientists rely on Actea to understand what is coming.
              </h3>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
          {/*
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<ErrorBoundary>
								<ActeaIntroVideo />
							</ErrorBoundary>
						</GridItem>
					</GridContainer>
					*/}
          <br></br>
          <ErrorBoundary>
            <WhatActeaDoes/>
          </ErrorBoundary>
          <ErrorBoundary>
            <HowActeaHelps/>
          </ErrorBoundary>
          <ErrorBoundary>
            <ProductList/>
          </ErrorBoundary>
				</div>

        <WorkSectionAlt/>

    </div>

			<ActeaFooter/>
			<Outlet/>

	</div>
  );
}
