import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";

const useStyles = makeStyles(styles);

import plot_image from "../../assets/img/example_types_of_plots.png";
import app_image from "../../assets/img/App_example.png";
import nfld_change from "../../assets/img/NFLD_Temp_Changes_desat.png"
import validation_image from "../../assets/img/Validation_Transparent.png"
import cod_habitat from "../../assets/img/cod_habitat_greyed.png";
import days_over_20 from "../../assets/img/days_over_20_desat.png";
import portfolio_img from "../../assets/img/portfolio_img_desat.png";
import sea_ice_validation from "../../assets/img/sea_ice_validation_desat.png";



export default function HowActeaHelps() {
  const classes = useStyles();

  return (
    <div className={classes.container}>

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.title} >How Actea can help you</h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <h4 className={classes.cardTitle}> For Regulators and Scientists</h4>
              <img src={nfld_change}  />
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <h5 style={{fontFamily:"Arial,sans-serif", alignment: 'center', color:"#3C4858"}}>
                <br></br>
                Actea is a contributor to NOAA’s <a href={"https://www.fisheries.noaa.gov/alaska/ecosystems/alaska-climate-integrated-modeling-project"} target={"_blank"}> ACLIM </a> project, evaluating climate impacts on marine fisheries in the Bering Sea, Alaska. Actea produced the statistical downscale included in the Norwegian Miljødirektoratet’s <a href={"https://www.miljodirektoratet.no/publikasjoner/2022/november/klimapavirkning-viktige-kystvannsarter/"} target={"_blank"}>Climate Impact on Important Coastal Water Species</a>.  We have also produced non-commercially licensed datasets used by the Canadian Department of Fisheries and Oceans, the Nunatsiavut Nation, the EU FutureMares project, and others. If you are a regulator, scientist, or government agency we can contract to produce and deliver the specific data you need to fulfill your mission. <a href={"#contactus"}>Contact us</a> to discuss your needs further.
              </h5>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <h4 className={classes.cardTitle}> For Mariculture<br />
              </h4>
              <h5 style={{fontFamily:"Arial,sans-serif", alignment: 'center', color:"#3C4858"}}> Actea can produce a site-specific analysis tailored to your operation. While the global expected ocean temperature change over this century is 0.6 degrees, we’ve analyzed aquaculture sites where the expected change is over 3 degrees, with extreme events making such sites unusable by the 2040s.  Actea can project changes in fish growth rates, baseline mortality, and the risk of mass-mortality events, based on underlying changes to ocean temperature and chemistry.
              </h5>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <p></p>
              <br/>
              <img src={days_over_20} />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <h4 className={classes.cardTitle}> For Commercial Fishing</h4>
              <img src={cod_habitat} />
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <br></br>
              <h5 style={{fontFamily:"Arial,sans-serif", alignment: 'center', color:"#3C4858"}}> Actea can produce an analysis of species availability in the waters you fish over the lifespan of a boat or a processing facility. Our analyses to date indicate that, while some species move out, others are likely to move in. Be ready to pursue an alternative species when the change arrives or choose to focus on a species likely to stick around.
              </h5>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <h4 className={classes.cardTitle}> For Complex Holdings <br />
              </h4>
              <h5 style={{fontFamily:"Arial,sans-serif", alignment: 'center', color:"#3C4858"}}> Diversification across species and regions can help a portfolio of investments be robust to climate disruption. However, simultaneous mortality events across assets can produce a disastrous drop in cash-flow.  Through simulation of multiple possible futures, Actea can model risk correlation within your portfolio and help you optimize geographic and species allocations to minimize disruptions.
              </h5>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <p></p>
              <br/>
              <img src={portfolio_img}  />
            </Card>
          </GridItem>


          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <h4 className={classes.cardTitle}> Data Validation</h4>
              <img src={validation_image}  />
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <br/>
              <h5 style={{fontFamily:"Arial,sans-serif", alignment: 'center', color:"#3C4858"}}> We produce climate projections from 1993-2099. We compare the historic portion of our projections against observations. This demonstrates that the climate distribution produced by our system is accurate. We also compute statistical downscales for a variety of different climate models and include future model variance in our analysis. <a href={"#contactus"}> Contact us</a> for more details.
              </h5>
            </Card>
          </GridItem>


        </GridContainer>
      </div>
    </div>
  );
}
