import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone';

const styles = theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	container: {
		width: '20px', // this does not work
	}
});

function CMIP6_seaice_list(props) {
	const { classes } = props;
	return (
		<div className={classes.root}>
			<List component="nav">
				<ListItem button>
					<ListItemIcon className="container">
						<FiberManualRecordTwoToneIcon style={{ fontSize: 10, color:"tomato"}} />
					</ListItemIcon>
					<ListItemText secondary="Sea-ice thickness" color="secondary" style={{display:'flex', justifyContent:'flex-start'}} />
				</ListItem>
				<ListItem button>
					<ListItemIcon className="container">
						<FiberManualRecordTwoToneIcon style={{ fontSize: 10, color:"tomato"}} />
					</ListItemIcon>
					<ListItemText secondary="Sea-ice concentration" color="secondary" style={{display:'flex', justifyContent:'flex-start'}} />
				</ListItem>
			</List>
		</div>
	);
}

CMIP6_seaice_list.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CMIP6_seaice_list);
