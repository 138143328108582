/*eslint-disable*/ import React from "react";
import ActeaFooter from "./ActeaFooter";
import GridContainer from "./components/Grid/GridContainer";
import GridItem from "./components/Grid/GridItem";
import { Link } from "react-router-dom";

export default function TOS() {


  return (
    <main>
      <GridContainer style={{ padding: 45 }}>
        <GridItem>
        <div>
          <h3>
            <a name="_Hlk94281954">Terms of Use Agreement</a>
          </h3>
        </div>
        <br></br>
      <p>Effective: January 28, 2022</p>
        <br></br>
      <h3>
        <strong>1. </strong>
        <strong>Acceptance of the Terms and Conditions.</strong>
      </h3>
      <h4>
        1.1 Actea Inc. (herein referred to as the “<strong>Actea</strong>,” “{" "}
        <strong>we</strong>,” “<strong>us</strong>” or “<strong>our</strong>”)
        provides and makes available this web site (the “<strong>Web Site</strong>
        ”). All use of the Web Site is subject to the terms and conditions contained
        in this Terms of Use Agreement (the “<strong>Agreement</strong>
        ”). Please read this Agreement carefully. By accessing, browsing or
        otherwise using the Web Site, you acknowledge that you have read,
        understood, and agree to be bound by this Agreement. If you do not accept
        the terms and conditions of this Agreement, you shall not access, browse or
        use the Web Site.
      </h4>
      <h4>
        1.2 You understand and agree that we may change this Agreement at any time
        without prior notice. You may read a current, effective copy of this
        Agreement at any time by selecting the “Terms of Use” link on the Web Site.
        The revised terms and conditions will become effective at the time of
        posting. Any use of the Web Site after such date shall constitute your
        acceptance of such revised terms and conditions. If any change to this
        Agreement is not acceptable to you, your sole remedy is to cease accessing,
        browsing and otherwise using the Web Site.
      </h4>
      <h4>
        1.3 Your access to and use of the Web Site is also subject to Actea’s
        Privacy Policy located at<Link to='/PP'> https://actea.earth/PP</Link>.
      </h4>
      <h3>
        <strong>2. </strong>
        <strong>Use of the Web Site.</strong>
      </h3>
      <h4>
        2.1 This Web Site contains material, including but not limited to software,
        text, graphics and images (collectively referred to as the “{" "}
        <strong>Content</strong>”). We may own the Content or portions of the
        Content may be made available to us through arrangements that we have with
        third-parties. The Content is protected by United States and foreign
        intellectual property laws. Unauthorized use of the Content may result in
        violation of copyright, trademark, and other laws. You have no rights in or
        to the Content, and you will not use, copy or display the Content except as
        permitted under this Agreement. No other use is permitted without our prior
        written consent. You must retain all copyright and other proprietary notices
        contained in the original Content on any copy you make of the Content. You
        may not sell, transfer, assign, license, sublicense, or modify the Content
        or reproduce, display, publicly perform, make a derivative version of,
        distribute, or otherwise use the Content in any way for any public or
        commercial purpose. The use or posting of any of the Content on any other
        web site or in a networked computer environment for any purpose is expressly
        prohibited. If you violate any part of this Agreement, your right to access
        and/or use the Content and Web Site shall automatically terminate and you
        shall immediately destroy any copies you have made of the Content.
      </h4>
      <h4>
        2.2 The trademarks, service marks, and logos of Actea (the “{" "}
        <strong>Actea Trademarks</strong>”) used and displayed on this Web Site are
        registered and unregistered trademarks or service marks of Actea. Other
        company, product, and service names located on the Web Site may be
        trademarks or service marks owned by third-parties (the “{" "}
        <strong>Third-Party Trademarks</strong>”, and, collectively with Actea
        Trademarks, the “<strong>Trademarks</strong>”). Nothing on this Web Site or
        in this Agreement should be construed as granting, by implication, estoppel,
        or otherwise, any license or right to use any Trademark displayed on this
        Web Site without the prior written consent of Actea specific for each such
        use. The Trademarks may not be used to disparage Actea or the applicable
        third-party, Actea’s or third-party’s products or services, or in any manner
        (using commercially reasonable judgment) that may damage any goodwill in the
        Trademarks. Use of any Trademarks as part of a link to or from any web site
        is prohibited without Actea’s prior written consent. <strong> </strong>All
        goodwill generated from the use of any Actea Trademark shall inure to
        Actea’s benefit.
      </h4>
      <h4>
        2.3 You agree not to: (a) take any action that imposes an unreasonable load
        on the Web Site’s infrastructure, (b) use any device, software or routine to
        interfere or attempt to interfere with the proper working of the Web Site or
        any activity being conducted on the Web Site, (c) attempt to decipher,
        decompile, disassemble or reverse engineer any of the software comprising or
        making up the Web Site, (4) delete or alter any material posted on the Web
        Site by Actea or any other person or entity, or (5) frame or link to any of
        the materials or information available on the Web Site.
      </h4>
      <h4>
        2.4 The Web Site contains links to third-party web sites (“{" "}
        <strong>External Sites</strong>”). These links are provided solely as a
        convenience to you and not as an endorsement by us of the content on such
        External Sites. The content of such External Sites is developed and provided
        by others. You should contact the site administrator or Webmaster for those
        External Sites if you have any concerns regarding such links or any content
        located on such External Sites.
      </h4>
      <h4>
        We are not responsible for the content of any linked External Sites and do
        not make any representations regarding the content or accuracy of any
        materials on such External Sites. You should take precautions when
        downloading files from all web sites to protect your computer from viruses
        and other destructive programs. If you decide to access any External Sites,
        you do so at your own risk.
      </h4>
      <h4>
        2.5 Certain<strong> </strong>elements of the Web Site are protected by trade
        dress, trademark, unfair competition, and other state and federal laws and
        may not be copied or imitated in whole or in part, by any means, including
        but not limited to, the use of framing or mirrors, except as otherwise
        expressly permitted by Section 2.1 of the Agreement. None of the Content for
        this Web Site may be retransmitted without the express written consent from
        Actea for each and every instance.
      </h4>
      <h3>
        <strong>3. </strong>
        <strong>Limitation of Liability and Disclaimer of Warranties.</strong>
      </h3>
      <h4>
        3.1 ACTEA, ITS AFFILIATES, THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
        AGENTS, SUPPLIERS, OR LICENSORS (COLLECTIVELY, THE “{" "}
        <strong>ACTEA PARTIES</strong>“) MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
        THE CONTENT, INCLUDING BUT NOT LIMITED TO ITS ACCURACY, RELIABILITY,
        COMPLETENESS, TIMELINESS OR RELIABILITY. ACTEA PARTIES SHALL NOT BE SUBJECT
        TO LIABILITY FOR THE TRUTH, ACCURACY OR COMPLETENESS OF THE CONTENT OR ANY
        OTHER INFORMATION CONVEYED TO THE USER OR FOR ERRORS, MISTAKES OR OMISSIONS
        THEREIN OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA OR INFORMATION STREAM
        FROM WHATEVER CAUSE. YOU AGREE THAT YOU USE THE WEB SITE AND THE CONTENT AT
        YOUR OWN RISK.
      </h4>
      <p>
        ACTEA PARTIES DO NOT WARRANT THAT THE WEB SITE WILL OPERATE ERROR‑FREE OR
        THAT THE WEB SITE, ITS SERVER, OR THE CONTENT ARE FREE OF COMPUTER VIRUSES
        OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF YOUR USE OF THE WEB
        SITE OR THE CONTENT RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT
        OR DATA, NO ACTEA PARTY SHALL BE RESPONSIBLE FOR THOSE COSTS.
      </p>
      <p>
        THE WEB SITE AND CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
        WITHOUT ANY WARRANTIES OF ANY KIND. ACTEA PARTIES DISCLAIM ALL WARRANTIES,
        INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF TITLE, MERCHANTABILITY,
        NON‑INFRINGEMENT OF THIRD PARTIES RIGHTS, AND FITNESS FOR PARTICULAR
        PURPOSE.
      </p>
      <h4>
        3.2 IN NO EVENT SHALL ANY ACTEA PARTY BE LIABLE FOR ANY DAMAGES WHATSOEVER
        (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST
        PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION)
        RESULTING FROM THE USE OR INABILITY TO USE THE WEB SITE AND THE CONTENT,
        WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY
        OTHER LEGAL THEORY, EVEN IF SUCH ACTEA PARTY HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES.
      </h4>
      <h4>
        3.3 SOME STATES DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN
        WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
        CONSEQUENTIAL DAMAGES. ACCORDINGLY, IN SUCH STATES, SOME OF THE ABOVE
        LIMITATIONS MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU, AND
        THE LIABILITY OF ACTEA PARTIES SHALL BE LIMITED TO THE GREATEST EXTENT
        PERMITTED BY LAW.
      </h4>
      <h4>
        3.4 IF YOU ARE FROM NEW JERSEY, THE FOREGOING SECTIONS 3.1 AND 3.2 ARE
        INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF
        NEW JERSEY. IF ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE
        LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION SHALL NOT
        AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
      </h4>
      <h4>
        <strong>4. </strong>
        <strong>Indemnification. </strong>
        You agree to defend, indemnify, and hold harmless Actea Parties from and
        against any claims, actions or demands, including, without limitation,
        reasonable legal and accounting fees, arising or resulting from your breach
        of this Agreement or your access to, use or misuse of the Content or Web
        Site. Actea shall provide notice to you of any such claim, suit, or
        proceeding. Actea reserves the right to assume the exclusive defense and
        control of any matter which is subject to indemnification under this
        section. In such case, you agree to cooperate with any reasonable requests
        assisting Actea’s defense of such matter.
      </h4>
      <h3>
        <strong>5. </strong>
        <strong>Termination of the Agreement.</strong>
      </h3>
      <h4>
        5.1 Actea reserves the right, in its sole discretion, to restrict, suspend,
        or terminate this Agreement and your access to all or any part of the Web
        Site or the Content at any time and for any reason without prior notice or
        liability. Actea reserves the right to change, suspend, or discontinue all
        or any part of the Web Site or the Content at any time without prior notice
        or liability.
      </h4>
      <h4>
        5.2 Sections 2 (Use of the Web Site), 3 (Limitation of Liability and
        Disclaimer of Warranties), 4 (Indemnification), 5 (Termination of
        Agreement), and 8 (Miscellaneous) shall survive the termination of this
        Agreement.
      </h4>
      <h3>
        <strong>6. </strong>
        <strong>User Must Comply with Applicable Laws.</strong>
      </h3>
      <h4>
        6.1 Actea is based in the United States. We make no claims concerning
        whether the Content may be downloaded, viewed, or be appropriate for use
        outside of the United States. If you access the Web Site or the Content from
        outside of the United States, you do so at your own risk. Whether inside or
        outside of the United States, you are solely responsible for ensuring
        compliance with the laws of your specific jurisdiction.
      </h4>
      <h4>
        6.2 The United States controls the export of products and information. You
        expressly agree to comply with such restrictions and not to export or
        re-export any of the Content to countries or persons prohibited under the
        export control laws. By downloading the Content, you are expressly agreeing
        that you are not in a country where such export is prohibited or are a
        person or entity for which such export is prohibited. You are solely
        responsible for compliance with the laws of your specific jurisdiction
        regarding the import, export, or re-export of the Content.
      </h4>
      <h3>
        <strong>7. </strong>
        <strong>U.S. Government Restricted Rights. </strong>
        The Content is provided with “RESTRICTED RIGHTS.” Use, duplication, or
        disclosure by the Government is subject to the restrictions contained in 48
        CFR 52.227-19 and 48 CFR 252.227-7013 et seq. or its successor. Use of the
        Web Site or Content by the Government constitutes acknowledgement of our
        proprietary rights in the Web Site and Content.
      </h3>
      <h3>
        <strong>8. </strong>
        <strong>Miscellaneous. </strong>
      </h3>
      <h4>
        This Agreement is governed by the internal substantive laws of the State of{" "}
        <em> </em>California, without respect to its conflict of laws provisions.
        You expressly agree to submit to the exclusive personal jurisdiction of the
        state and federal courts sitting in the City of San Francisco in the State
        of California. If any provision of this Agreement is found to be invalid by
        any court having competent jurisdiction, the invalidity of such provision
        shall not affect the validity of the remaining provisions of this Agreement,
        which shall remain in full force and effect. Failure of Actea to act on or
        enforce any provision of the Agreement shall not be construed as a waiver of
        that provision or any other provision in this Agreement. No waiver shall be
        effective against Actea unless made in writing, and no such waiver shall be
        construed as a waiver in any other or subsequent instance. Except as
        expressly agreed by Actea and you, this Agreement constitutes the entire
        Agreement between you and Actea with respect to the subject matter, and
        supercedes all previous or contemporaneous agreements, whether written or
        oral, between the parties with respect to the subject matter. The section
        headings are provided merely for convenience and shall not be given any
        legal import. This Agreement will inure to the benefit of our successors,
        assi
        <a name="SelTemp" />
        gns, licensees, and sublicensees. Any information submitted or provided by
        you to the Web Site might be publicly accessible. Important and private
        information should be protected by you. Actea is not liable for protection
        of privacy of electronic mail or other information transferred through the
        Internet or any other network that you may use.
      </h4>
        </GridItem>
      </GridContainer>
          <ActeaFooter/>

    </main>
  );
}
