import React from "react";
import ReactDOM from 'react-dom';
import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";
import App from './App'
import TOS from "./TOS"
import PP from "./PP"
import RefBkgrnd from "./RefBkgrnd";
import Team from "./Team";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />} />
				<Route path="/TOS" element={<TOS />} />
				<Route path="/PP" element={<PP />} />
				<Route path="/RefBkgrnd" element={<RefBkgrnd />} />
				<Route path="/Team" element={<Team />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
