import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";
import {DoneRounded} from "@material-ui/icons";
import CMIP6_physics_list from "LandingPage/Sections/CMIP6_physics_list.js";
import CMIP6_biology_list from "LandingPage/Sections/CMIP6_biology_list.js";
import CMIP6_seaice_list from "LandingPage/Sections/CMIP6_seaice_list.js";
import ProductInfoArea from "./ProductInfoArea";
import Product_site_list from "./Product_site_list";
import Product_complex_list from "./Product_complex_list";
import Product_fishery_list from "./Product_fishery_list";
import Product_site_daily_list from "./Product_site_daily_list";

const useStyles = makeStyles(styles);

export default function ProductList() {
  const classes = useStyles();
  const bgColor1 =  "rgb(236, 247, 250)"	;
  const bgColor2 =  "rgb(210,234,241)";
  const bgColor3 =  "rgb(172,208,219)";
  const bgColor4 = "rgb(128,172,194)";
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Products and Prices</h2>
          <h5 className={classes.description} style={{color: "#3C4858"}}>
            Depending on your needs we have multiple tiers of engagement.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3} style={{backgroundColor: bgColor1 }}>
            <ProductInfoArea
              title={"Wild Fish"}
              subtitle={"Climate Report"}
              resolution={"Monthly Resolution"}
              price={"$10,000 per 10° x 10° area"}
              bgColor={bgColor1}
              description="Understand regional climate impacts to a single fish species.
Discounts available for multiple species and larger regions. The report includes projections of:"
              vertical
            />
            <
              Product_fishery_list bgColor={bgColor1}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={3} style={{backgroundColor: bgColor2}}>
            <ProductInfoArea
              title={"Mariculture Site"}
              subtitle={"Climate Report"}
              resolution={"Monthly Resolution"}
              price={"$10,000 per Site"}
              bgColor={bgColor2}
              description="Understand how climate change will impact growth rates and baseline mortality at a mariculture site. The report includes projections of:"
              vertical
            />
            <
              Product_site_list bgColor={bgColor2}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} style={{backgroundColor: bgColor3}}>
            <ProductInfoArea
              title={"Mariculture Site"}
              subtitle={"Climate Report"}
              resolution={"Daily Resolution"}
              price={"$50,000 per Site"}
              bgColor={bgColor3}
              description="30x the data for 5x the price. Daily data allows for better understanding of extreme events. All variables included in the monthly analysis plus:"
              vertical
            />
            <
              Product_site_daily_list bgColor={bgColor3}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} style={{backgroundColor: bgColor4}}>
            <ProductInfoArea
              title={"Complex Holdings"}
              subtitle={"Climate Analysis"}
              resolution={"Daily Resolution"}
              price={"Custom Pricing"}
              bgColor={bgColor4}
              description="Understand and improve your whole business's climate resilience. Localized global models allow for comparison of conditions across continents. "
              vertical
            />
            <
              Product_complex_list bgColor={bgColor4}
            />
          </GridItem>

        </GridContainer>
      </div>
    </div>
  );
}
