/*eslint-disable*/ import React from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import GridItem from "./components/Grid/GridItem";
import { Link } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import HeaderLinks from "./components/Header/HeaderLinks";
const dashboardRoutes = [];

export default function ActeaHeader(props) {
  const { ...rest } = props;


  return (
    <ErrorBoundary>
      <div style={{padding:20}}></div>
      <Header
        color="white"
        routes={dashboardRoutes}
        brand="Actea Inc"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 0,
          color: "white"
        }}
        {...rest}
      />
    </ErrorBoundary>
  );
}
