import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

import styles from "assets/jss/material-kit-pro-react/components/infoStyle.js";

const useStyles = makeStyles(styles);

export default function ProductInfoArea(props) {
  const { title, subtitle, resolution, price, description, bgColor, vertical, className } = props;
  const classes = useStyles();
  const productInfoAreaClasses = classNames({
    [classes.productInfoArea]: true,
    [className]: className !== undefined,
  });
  return (
    <div className={productInfoAreaClasses} style={{backgroundColor: bgColor}}>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title} <br/> {subtitle} </h4>
        <h4 className={classes.title}>{resolution}</h4>
        <h4 className={classes.title}>{price}</h4>
        <h5  style={{color: "#3C4858"}} >{description}</h5>
      </div>
    </div>
  );
}

ProductInfoArea.defauldtProps = {
  bgColor: "gray",
};

ProductInfoArea.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  iconColor: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  vertical: PropTypes.bool,
  className: PropTypes.string,
};
