/*eslint-disable*/ import React from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import GridItem from "./components/Grid/GridItem";
import GridContainer from "./components/Grid/GridContainer";
import { Link } from "react-router-dom";
import Footer from "./components/Footer/Footer";


import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);


export default function ActeaFooter() {
  const classes = useStyles();

  return (
    <div className={classes.section}>
    <Footer justify="center">
      <ErrorBoundary>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={12}  justify="center">
            <div style={{textAlign:"center"}}><p style={{textAlign:"center"}}>2024 © Actea Inc &nbsp; | &nbsp;
              <Link to="/TOS">Terms of Use Agreement</Link> &nbsp; | &nbsp;
              <Link to="/PP">Privacy Policy</Link> &nbsp; | &nbsp;
              <Link to="/Team">Team</Link>
            </p></div>
          </GridItem>
        </GridContainer>
      </ErrorBoundary>
    </Footer>
    </div>

  );
}
